(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championship.assignMatches.controller:AssignMatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.championships.championship.assignMatches')
    .controller('AssignMatchesCtrl', AssignMatchesCtrl);

  function AssignMatchesCtrl($filter, $mdToast, ChampionshipMatches, AclService, championshipCalendar, championship, categories, sports, genders, towns, facilities, entities) {
    var vm = this;
    vm.ctrlName = 'AssignMatchesCtrl';

    vm.championship = championship;

    vm.category = $filter('translate')('CATEGORIES.' + findItemById(categories, vm.championship.categoryId).name);

    vm.sport = $filter('translate')('SPORTS.' + findItemById(sports, vm.championship.sportId).name);
    vm.gender = $filter('translate')(findItemById(genders, vm.championship.genderId).name);
    vm.teams = buildTeams(championshipCalendar.teams);
    vm.myTeamsId = buildMyTeams(vm.teams, entities);

    vm.facilities = buildFacilities(championshipCalendar.facilities.concat(facilities), towns);
    vm.myFacilities = buildFacilities(facilities, towns);
    vm.matchDays = buildMatchDays(championshipCalendar.matchDays, vm.teams, vm.facilities, championshipCalendar.sportMatchDay);

    vm.getTeamName = getTeamName;
    vm.getMatchFacilities = getMatchFacilities;
    vm.getFacilityName = getFacilityName;
    vm.saveMatches = saveMatches;

    vm.canEditScore = canEditScore;
    vm.canEditDate = canEditDate;
    vm.canEditTime = canEditTime;
    vm.canEditFacility = canEditFacility;

    function getTeamName(teamId) {
      var team = vm.teams[teamId];
      return angular.isUndefined(team) ? undefined : team.teamInfo.name;
    }

    function getMatchFacilities(match) {
      if (match.facilityId === null) {
        return vm.myFacilities;
      } else {
        var assignedFacility = {};
        assignedFacility[match.facilityId] = vm.facilities[match.facilityId];

        // TODO: Revise if it's necesary precalculate the list
        var myFacilities = _.clone(vm.myFacilities);
        return _.assign(myFacilities, assignedFacility);
      }
    }

    function getFacilityName(facilityId) {
      var facility = vm.facilities[facilityId];
      return angular.isUndefined(facility) ? undefined : facility.name;
    }

    function buildTeams(teams) {
      var newTeams = {};
      angular.forEach(teams, function (team) {
        newTeams[team.teamInfo.id] = team;
      });
      return newTeams;
    }

    function buildMyTeams(teams, entities) {
      var entitiesId = _.map(entities, function (e) {
        return e.id;
      });
      var myTeams = _.filter(teams, function (t) {
        return _.contains(entitiesId, t.publicEntityModel.id);
      });
      return _.map(myTeams, function (t) {
        return t.teamInfo.id;
      });
    }

    function buildFacilities(facilities, towns) {
      var newFacilities = {};
      angular.forEach(facilities, function (facility) {
        newFacilities[facility.id] = facility;
        newFacilities[facility.id].town = findItemById(towns, facility.idTown);
      });
      return newFacilities;
    }

    function buildMatchDays(matchDays, sportMatchDay) {
      angular.forEach(matchDays, function (matchDay) {
        matchDay.matches = buildMatches(matchDay.matches);
        matchDay.sportMatchDay = false;
      });
      matchDays = addSportMatchDay(matchDays, sportMatchDay);
      return matchDays;
    }

    function buildMatches(matches) {
      var newMatches = [];
      angular.forEach(matches, function (match) {
        newMatches.push({
          id: match.id,
          date: moment(match.date).toDate(),
          time: _.isNull(match.time) ? null : moment(match.time, 'HH:mm').toDate(),
          localScore: match.localScore,
          visitorScore: match.visitorScore,
          localTeamId: match.localTeamId,
          visitorTeamId: match.visitorTeamId,
          facilityId: match.facilityId
        });
      });
      return newMatches;
    }

    function addSportMatchDay(matchDays, sportMatchDay) {
      matchDays = _.sortBy(matchDays, 'date');
      if (_.size(matchDays) && sportMatchDay > _.head(matchDays).date && sportMatchDay < _.last(matchDays).date) {
        matchDays.push({
          sportMatchDay: true,
          date: sportMatchDay
        });
      }
      return _.sortBy(matchDays, 'date');
    }

    function saveMatches() {
      var matchesToSave = [];
      angular.forEach(vm.matchDays, function (matchDay) {
        angular.forEach(matchDay.matches, function (match) {
          if (canEditMatch(match)) {
            matchesToSave.push({
              id: match.id,
              date: moment(match.date).format('YYYY-MM-DD'),
              time: _.isNull(match.time) ? null : moment(match.time).format('HH:mm'),
              localScore: match.localScore,
              visitorScore: match.visitorScore,
              localTeamId: match.localTeamId,
              visitorTeamId: match.visitorTeamId,
              facilityId: match.facilityId
            })
          }
        });
      });
      ChampionshipMatches.update({id: vm.championship.id}, matchesToSave, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CHAMPIONSHIPS.MATCHES_SUCCESSFUL_UPDATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
      }, function (err) {
        var message = err.data.message || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }


    function canEditMatch(match) {
      return canEditScore(match) || canEditDate(match) || canEditTime(match) || canEditFacility(match);
    }

    function canEditScore(match) {
      //TODO: Revisar lógica en API
      return !vm.championship.jvp && (AclService.isAdmin() || vm.championship.owner);
    }

    function canEditDate(match) {
      return AclService.isAdmin() || vm.championship.owner;
    }

    function canEditTime(match) {
      return (AclService.isAdmin() || vm.championship.owner || _.contains(vm.myTeamsId, match.localTeamId)) && !_.isNull(match.visitorTeamId) && !_.isNull(match.localTeamId);
    }

    function canEditFacility(match) {
      return (AclService.isAdmin() || vm.championship.owner || _.contains(vm.myTeamsId, match.localTeamId)) && !_.isNull(match.visitorTeamId) && !_.isNull(match.localTeamId);
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

  }
}());
